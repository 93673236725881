import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import hambergerMenu from "../../../assets/images/icons/hamburger_menu.svg";
import backbutton from "../../../assets/images/icons/back_button.svg";
import navmenu_arrow from "../../../../src/assets/images/icons/nav_menu_arrow.svg";
import PersonIcon from "../../../../src/assets/images/icons/navigation/person.svg";
import CoinReward from "../../../../src/assets/images/icons/navigation/coin-reward.svg";
import Jackpot from "../../../../src/assets/images/icons/navigation/jackpot.svg";
import InfoIcon from "../../../../src/assets/images/icons/navigation/Info.svg";
import NotifyIcon from "../../../../src/assets/images/icons/navigation/notification.svg";
import EnglishFlag from "../../../../src/assets/images/Lang/english_flag.svg";
import IndonesiaFlag from "../../../../src/assets/images/Lang/indonesia_flag.svg";
import LogoutIcon from "../../../../src/assets/images/icons/navigation/logout.svg";
import PromotionIcon from "../../../../src/assets/images/HeadIcon/promotion_icon.svg";
import CrossIcon from "../../../../src/assets/images/icons/navigation/cross_icon.svg";
import i18next from 'i18next';
import { useDispatch, useSelector } from "react-redux";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import { TOKEN_KEY } from "../../../helpers/url_helper";
import { withTranslation } from "react-i18next";
import Modal from "../../Modal/Modal";
import LangIcon from "../../../assets/images/icons/navigation/lang_icon.svg";
import Logo from "./HeaderLeftMenu/Logo";
import hoverMusic from "../../../../src/assets/music/hovernoise.mp3";
import clickMusic from "../../../../src/assets/music/clicknoise.wav";
import MuteIcon from "../../../../src/assets/images/HeadIcon/Mute.svg";
import UnmuteIcon from "../../../../src/assets/images/HeadIcon/UnMute.svg";
import audioManager from "../../AudioManager";
import musicFile from "../../../../src/assets/music/hovermusic.mp3"

const HeaderRightMenu = (props) => {
  const navigate = useNavigate();
  const user_balance = useSelector((state) => state.UserBalance.user_balance);
  const storedLanguage = localStorage.getItem("I18N_LANGUAGE") || "id";
  const isEnglish = storedLanguage === "en";
  const [sidebar, setSidebar] = useState(false);
  const [hideBurgerMenu, setHideBurgerMenu] = useState(true);
  const defaultLanguage = localStorage.getItem("I18N_LANGUAGE") || "id";
  const [languageChange, setLanguageChange] = useState(isEnglish ? "Indonesia" : "English");
  const [languageName, setLanguageName] = useState(isEnglish ? "English" : "Indonesia");
  const [flagChange, setFlagChange] = useState(isEnglish ? EnglishFlag : IndonesiaFlag);
  const [flagAfterChange, setFlagAfterChange] = useState(isEnglish ? IndonesiaFlag : EnglishFlag);
  const [showLanguageOption, setShowLanguageOption] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [playMusicState, setPlayMusicState] = useState('Unmute')
  const [musicIcon, setMusicIcon] = useState(UnmuteIcon);

  const handleLogout = () => {
    setShowLogoutPopup(true);
    setTimeout(() => {
      makeAjax(url.PLAYER_API.logout, null, url.PLAYER_MS_EXT).then(
        (response) => {
          if (response.status !== respStatus["SUCCESS"]) {
            showMessage(response);
            localStorage.removeItem(TOKEN_KEY);
            setTimeout(() => {
              window.close();
            }, 2000);
            return;
          }
          localStorage.removeItem(TOKEN_KEY);
          setTimeout(() => {
            window.close();
          }, 1000);
        }
      );
    }, 3000);
  };

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  const hideSideBarOn = () => {
    setSidebar(false);
    setShowLanguageOption(false);
  };

  useEffect(() => {
    setHideBurgerMenu(window.location.pathname === "/" ? false : true);
  }, [navigate]);

  const showLanguageModal = () => {
    setShowLanguageOption(true);
  };

  const closeLanguageCross = () => {
    setShowLanguageOption(false);
  };

  const closeModal = () => {
    setShowLogoutPopup(false);
  };

  const languageChangeOption = () => {
    const newLanguage = languageName === "English" ? "id" : "en";
    const reqData = { lang: newLanguage };

    makeAjax(url.PLAYER_API.updateUserLanguage, reqData, url.PLAYER_MS_EXT).then(
      (response) => {
        if (response.status !== respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        localStorage.setItem("I18N_LANGUAGE", newLanguage);
        i18next.changeLanguage(newLanguage);
        setLanguageName(newLanguage);

        // Update local state based on the new language
        if (languageName === "English") {
          setLanguageName("Indonesia");
          setLanguageChange("English");
          setFlagChange(IndonesiaFlag);
          setFlagAfterChange(EnglishFlag);
        } else {
          setLanguageName("English");
          setLanguageChange("Indonesia");
          setFlagChange(EnglishFlag);
          setFlagAfterChange(IndonesiaFlag);
        }
        setShowLanguageOption(false);
      }
    );
  };

  const closeSidebarOption = () => {
    setSidebar(false);
  };

  const handleBackClick = () => {
    // dispatch(setFooterActiveClass("home"))
  };

  const handleStopMusic = () => {
    if (playMusicState == "Unmute") {
      setPlayMusicState("Mute");
      setMusicIcon(MuteIcon)
      setTimeout(() => {
        audioManager.pause("background"); // Pause the audio
        audioManager.removeAudio("background");
      }, 0)
    }
    else {
      setPlayMusicState("Unmute");
      setMusicIcon(UnmuteIcon)
      setTimeout(() => {
        if (!audioManager.audioInstances.has("background")) {
          audioManager.addAudio("background", musicFile, true, 0.05);
          // audioManager.initPlayOnLoad('background', 0.05);
        }
        audioManager.play("background", 0.05);
      }, 0)
    }
  }

  const SidebarData = [
    {
      title: props.t('profile'),
      path: "/profile",
      icon: PersonIcon,
    },
    {
      title: props.t('promotion'),
      path: "/promotion",
      icon: PromotionIcon,
    },
    {
      title: props.t('reward'),
      path: "/coinReward",
      icon: CoinReward,
    },
    {
      title: "Jackpot",
      path: "/jackpot",
      icon: Jackpot,
    },
    {
      title: props.t('information'),
      path: "/information",
      icon: InfoIcon,
    },
    {
      title: props.t('notification'),
      path: "/notification",
      icon: NotifyIcon,
    },
    {
      title: "English",
      path: null,
      icon: EnglishFlag,
      onClick: showLanguageModal,
    },
    {
      title:  props.t('Music'),
      path: null,
      icon: musicIcon,
      onClick: handleStopMusic,
    },
    {
      title: props.t('logout'),
      path: null,
      icon: LogoutIcon,
      onClick: handleLogout,
    },
  ];

  return (
    <>
      <div className="nav-rightInfo-bar">
        {hideBurgerMenu ? (
          <Link to="/" className="menu-bar">
            <img src={backbutton} alt="" onClick={handleBackClick} />
          </Link>
        ) : (
          <Link to="#" className="menu-bar">
            <img src={hambergerMenu} onClick={showSidebar} alt="" />
          </Link>
        )}
      </div>

      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <div className="nav-menu-overlay" onClick={hideSideBarOn}></div>
        <div className="nav-menu-list">
          <ul className="nav-menu-item">
            <li className="nav-list-li logo-mobile">
              <Logo />
            </li>
            {SidebarData.map((item, index) => (
              <li key={index} className="nav-list-li">
                <Link
                  to={item.path}
                  onClick={
                    item.path === null ? item.onClick : closeSidebarOption
                  }
                >
                  <div className="nav-menu-icon">
                    {item.title === "English" ? (
                      <img src={flagChange} alt="" />
                    ) : (
                      <img src={item.icon} alt="" />
                    )}
                    {item.title === props.t("notification") ? (
                      <span className="nav_notify_count">
                        {user_balance.countNotif}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {item.title === "English" ? (
                    <p className="nav-menu-title">{languageName}</p>
                  ) : (
                    <p className="nav-menu-title">{item.title}</p>
                  )}
                  {(item.title !== "Music") && (
                    <span className="nav-menu-arrow">
                      <img src={navmenu_arrow} alt="" />
                    </span>
                  )}
                </Link>
                {item.title === "English" && showLanguageOption && (
                  <div className="nav-language">
                    <div className="nav-language-row">
                      <img src={LangIcon} alt="" />
                      <p>{props.t("language")}</p>
                      <div className="nav-language-close">
                        <img
                          src={CrossIcon}
                          alt=""
                          onClick={closeLanguageCross}
                        />
                      </div>
                    </div>
                    <div
                      className="nav-language-row"
                      onClick={languageChangeOption}
                    >
                      <div className="nav-language-icon">
                        <img src={flagAfterChange} alt="" />
                      </div>
                      <p>{languageChange}</p>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
          {showLogoutPopup && (
            <div className="logout_modal">
              <Modal
                modalHeader={true}
                modalTitle={props.t("message")}
                onCloseModal={closeModal}
              >
                <div className="nick_success_message">
                  <p>{props.t("you_ve_been_logged_out_successfully")}!</p>
                </div>
              </Modal>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default withTranslation()(HeaderRightMenu);
