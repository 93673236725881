import audioManager from "./AudioManager";
export const playMusicEvent = (action, hoverMusic, loop, volume) => {
    setTimeout(() => {
      if (!audioManager.audioInstances.has(action)) {
          audioManager.addAudio(action, hoverMusic, loop, volume);
      }
      audioManager.play(action, volume);
    }, 0)
  }

export const stoMusicEvent = (action) => {
    setTimeout(() => {
      audioManager.pause(action); // Pause the audio
      audioManager.removeAudio(action);
    }, 0)
  }