import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import productBlueBg from "../../../assets/images/home_main/game_item_blue_bg.svg";
import productBlueBgMo from "../../../assets/images/home_main/game_item_blue_bg_mo.svg";
import productBrownBg from "../../../assets/images/home_main/game_item_brown_bg.svg";
import productBrownBgMo from "../../../assets/images/home_main/game_item_brown_bg_mo.svg";
import productPurpleBg from "../../../assets/images/home_main/game_item_purple_bg.svg";
import productPurpleBgMo from "../../../assets/images/home_main/game_item_purple_bg_mo.svg";
import promoTagBg from "../../../assets/images/home_main/promo_tag_bg.svg";
import hotTagBg from "../../../assets/images/home_main/hot.svg";
import newTagBg from "../../../assets/images/home_main/new.svg";
import underMaintenance from "../../../assets/images/home_main/maintenance.svg";
import Modal from "../../Modal/Modal";
import texasPokerImg from "../../../assets/images/GameList/Texas-poker.svg";
import dominoQqImg from "../../../assets/images/GameList/domino-qq.svg";
import BigTwo from "../../../assets/images/GameList/big-two.svg";
import cemeImg from "../../../assets/images/GameList/ceme.svg";
import restricted from "../../../assets/images/Rest.svg";
import cemeFighterImg from "../../../assets/images/GameList/ceme-fighter.svg";
import blackJackImg from "../../../assets/images/GameList/black-jack.svg";
import pokerDealerImg from "../../../assets/images/GameList/poker-dealer.svg";
import bolaTangkasImg from "../../../assets/images/GameList/bola-tangakas.svg";
import bigTwoImg from "../../../assets/images/GameList/big-two.svg";
import super10Img from "../../../assets/images/GameList/super-ten.svg";
import omahaPokerImg from "../../../assets/images/GameList/omaha-poker.svg";
import threeKingsImg from "../../../assets/images/GameList/three-kings.svg";
import jokerDealerImg from "../../../assets/images/GameList/joker-dealer.svg";
import aduQImg from "../../../assets/images/GameList/adu-q.svg";
import banderQqImg from "../../../assets/images/GameList/bander-qq.svg";
import gapleImg from "../../../assets/images/GameList/gaple.svg";
import pockerDiceImg from "../../../assets/images/GameList/poker-dice.svg";
import rokakUrukImg from "../../../assets/images/GameList/rokak-uruk.svg";
import jisongImg from "../../../assets/images/GameList/jisong.svg";
import texasPokerClassicImg from "../../../assets/images/GameList/Texas-Poker-Classic.svg";
import baccaratImg from "../../../assets/images/GameList/Baccarat.svg";
import bandarQqImg from "../../../assets/images/GameList/Bandar-QQ.svg";
import daduPokerImg from "../../../assets/images/GameList/Dadu-Poker.svg";
import bullBullDiceImg from "../../../assets/images/GameList/Bull-Bull-Dice.svg";
import tangkasClassicImg from "../../../assets/images/GameList/Tangkas-Classic.svg";
import StarImg from "../../../assets/images/sining-star.svg";
import StarImg2 from "../../../assets/images/sining-star-2.svg";
import { withTranslation } from "react-i18next";
import AutoTablePopup from "./AutoTable";
import {playMusicEvent, stoMusicEvent} from "../../MusicFunctions.js";

import productNewYearThemBlue from "../../../assets/images/home_main/game_item_blue_bg-newyear.svg";
import productNewYearThemBrown from "../../../assets/images/home_main/game_item_brown_bg-newyear.svg";
import productNewYearThemPurple from "../../../assets/images/home_main/game_item_purple_bg-newyear.svg";

import audioManager from "../../AudioManager";
import hoverMusic from "../../../assets/music/hovernoise.mp3";
import clickMusic from "../../../assets/music/clicknoise.wav";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import TableDataInfo from "./TableDataInfo";
import { setCommonConfig } from "../../../redux/actions";

const MainCardGameIcon = (props) => {
  const navigate = useNavigate();
  const device = useSelector((state) => state.CommonConfig.device_type);
  const [showModal, setShowModal] = useState(false);
  const [gameIconInRow, setgameIconInRow] = useState();
  const [iconRowClass, seticonRowClass] = useState();
  const [selectedGameName, setSelectedGameName] = useState("");
  const [selectedGameCode, setSelectedGameCode] = useState("");
  const [selectedGameId, setSelectedGameId] = useState("");
  const [gameList, setGameList] = useState([]);
  const [showAutoTableLimitPopup, setShowAutoTableLimitPopup] = useState(false);
  const [userLevel, setUserLevel] = useState("");
  const [blockedGames, setBlockedGames] = useState();
  const [showTooltip, setShowTooltip] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [clickedRestrictedGameId, setClickedRestrictedGameId] = useState(null); // State to track the clicked restricted game
  const festiveTheme = 2;
  const dispatch = useDispatch();

  const blue_game_bg = device !== "Mobile" ? productBlueBg : productBlueBgMo;
  const yellow_game_bg = device !== "Mobile" ? productBrownBg : productBrownBgMo;
  const purple_game_bg = device !== "Mobile" ? productPurpleBg : productPurpleBgMo;
  const gameListImages = {
    "Texas Poker": texasPokerImg,
    101: texasPokerImg,

    "Poker Dealer": pokerDealerImg,
    102: pokerDealerImg,

    "Game Ceme": cemeImg,
    103: cemeImg,

    "Ceme Fighter": cemeFighterImg,
    104: cemeFighterImg,

    "Black Jack": blackJackImg,
    106: blackJackImg,

    "Bola Tangkas": bolaTangkasImg,
    108: bolaTangkasImg,

    "Super 10": super10Img,
    109: super10Img,

    "Omaha Poker": omahaPokerImg,
    110: omahaPokerImg,

    "Three Kings": threeKingsImg,
    111: threeKingsImg,

    "Texas Poker Classic": texasPokerClassicImg,
    151: texasPokerClassicImg,

    "Joker Dealer": jokerDealerImg,
    112: jokerDealerImg,

    "Adu-Q": aduQImg,
    113: aduQImg,

    "Baccarat": baccaratImg,
    115: baccaratImg,

    "Bandar QQ": bandarQqImg,
    116: bandarQqImg,

    "Gaple": gapleImg,
    117: gapleImg,

    "Dadu Poker": daduPokerImg,
    118: daduPokerImg,

    "Rokak Uruk": rokakUrukImg,
    119: rokakUrukImg,

    "Bull Bull Dice": bullBullDiceImg,
    114: bullBullDiceImg,

    "Tangkas Classic": tangkasClassicImg,
    158: tangkasClassicImg,

    "Jisong": jisongImg,
    120: jisongImg,

    "Domino QQ": dominoQqImg,
    105: dominoQqImg,

    "Big Two": BigTwo,
    107: BigTwo,
  };

  const featchGameList = () => {
    makeAjax(url.CARD_GAME_API.getGameList).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        if (response.code !== "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      const gameListData = response.data.gamelist.map((game) => ({
        name: game.game_name,
        code: game.game_code || "",
        id: game.game_id,
        status: game.status,
        image:
          gameListImages[game.game_id] ||
          gameListImages[game.game_name] ||
          null,
        label_lobby: game.label_lobby || "",
        status: game.status || "",
        msgRestricted: game.msgRestricted || null,
      }));
      setGameList(gameListData);
      setBlockedGames(response.data.userBlockGameList);
    });
  };



  useEffect(() => {
    function checkScreenSize() {
      if (window.innerWidth <= 600) {
        setgameIconInRow(3);
        seticonRowClass("threeInOneRow");
      } else if (window.innerWidth >= 601 && window.innerWidth <= 800) {
        setgameIconInRow(5);
        seticonRowClass("fiveInOneRow");
      } else if (window.innerWidth >= 800 && window.innerWidth <= 1030) {
        setgameIconInRow(6);
        seticonRowClass("sixInOneRow");
      } else if (window.innerWidth >= 1030) {
        setgameIconInRow(8);
        seticonRowClass("EightInOneRow");
      }
    }
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, [gameIconInRow, iconRowClass, showModal]);

  const fetchUserCardGameLevel = async (gameCode, gameId) => {
    const reqData = {
      gameCode: gameCode,
      gameId: gameId
    };
    return makeAjax(url.CARD_GAME_API.getUserCardGameLevel, reqData).then(
      (response) => {
        if (response.status !== respStatus["SUCCESS"]) {
          if (response.code !== "validation_error") {
            showMessage(response);
            return null;
          }
          showMessage(response);
          return null;
        }
        setUserLevel(response.data.user_level);
        dispatch(setCommonConfig({ key: "userLevel", value: response.data.user_level }));
        return response.data.user_level;
      }
    );
  };

  const fetchRandomTableGames = async () => {
    const response = await makeAjax(url.CARD_GAME_API.getRandomTableGames, {});
    if (response.status !== respStatus["SUCCESS"]) {
      if (response.code !== "validation_error") {
        showMessage(response);
        return;
      }
      showMessage(response);
      return;
    }
    return response;
  };
  useEffect(() => {
    featchGameList();

  }, []);

const handleClick = async (action, gameName, gameCode, gameId) => {
  dispatch(setCommonConfig({ key: "userLevel", value: null }));

  setTimeout(() => {
    if (!audioManager.audioInstances.has(action)) {
      audioManager.addAudio(action, clickMusic, false, 1.0);
    }
    audioManager.play(action, 1.0);
  }, 0);

    if (!gameCode) {
      return;
    }
    gameCode = gameCode.toLowerCase();
    setSelectedGameName(gameName);
    setSelectedGameCode(gameCode);
    setSelectedGameId(gameId);

    setIsDataLoading(true);

    const response = await fetchRandomTableGames();
    if (response && response.data.randomtable_games.includes(gameId.toString())) {
      const level = await fetchUserCardGameLevel(gameCode, gameId);
      if (level === 0) {
        setShowAutoTableLimitPopup(true);
        setIsDataLoading(false);
        return;
      }
    }

    setIsDataLoading(false);
    if (device === "Mobile") {
      navigate("/gamedata", {
        state: {
          selectedGameName: gameName,
          gameCode: gameCode,
          gameListImages: gameListImages,
          gameId: gameId,
        },
      });
    } else {
      setShowModal(true);
    }
  };


  // Function to preload images for faster loading
  useEffect(() => {
    const preloadImages = () => {
      const imagesToLoad = Object.values(gameListImages);
      const promises = imagesToLoad.map((src) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
        });
      });

      Promise.all(promises).then(() => {
        // console.log("=All images are preloaded");
      });
    };
    preloadImages();
  }, [gameListImages]);
  const handleRestrikted = (e, gameId) => {
    e.stopPropagation();
    if (device === "Mobile") {
      if (clickedRestrictedGameId === gameId) {
        // If the same item is clicked again, toggle it off
        setClickedRestrictedGameId(null);
      } else {
        // Set the clicked game ID
        setClickedRestrictedGameId(gameId);
      }
    }


    const tooltip = e.currentTarget.querySelector('.custom-tooltip-text');
    const rect = tooltip.getBoundingClientRect();

    // Check if the tooltip goes outside the viewport
    if (rect.left < 0) {
      tooltip.style.left = '0';
      tooltip.style.transform = 'translateX(0)';
    } else if (rect.right > window.innerWidth) {
      tooltip.style.left = 'auto';
      tooltip.style.right = '50%';
      tooltip.style.transform = 'translateX(0)';
    }

  }
  const [hoveredGameId, setHoveredGameId] = useState(null);

  return (
    <>
      {gameList.map((game, index) => (
        <div
          key={index}
          className={`game_item ${iconRowClass} ${game.msgRestricted ? "game_item_restrikted" : ""
            } ${game.status === 2 && "no-pointer"}`}
          onClick={() => {
            if (blockedGames && blockedGames.includes(game.id)) {
              // Show the message when the blocked game is clicked
              showMessage(`Sorry, you are not allowed to access the game (${game.name})`, "Message");
            } else if (game.status !== 2 && game.msgRestricted === null) {
              // Handle other game clicks as usual
              handleClick("clickMusic", game.name, game.code, game.id);
            }
          }}
        >
          {
            festiveTheme === 2 ? (
              <div className="game_item_bg item_blue">
                <img
                  src={
                    index % (gameIconInRow * 3) < gameIconInRow
                      ? productNewYearThemBlue
                      : index % (gameIconInRow * 3) < gameIconInRow * 2
                        ? productNewYearThemBrown
                        : productNewYearThemPurple
                  }
                  alt=""
                /></div>
            ) : (
              <div className="game_item_bg item_blue">
                <img
                  src={
                    index % (gameIconInRow * 3) < gameIconInRow
                      ? blue_game_bg
                      : index % (gameIconInRow * 3) < gameIconInRow * 2
                        ? yellow_game_bg
                        : purple_game_bg
                  }
                  alt=""
                />
              </div>
            )
          }
          {/* <div className="game_item_bg item_blue">
            <img
              src={
                index % (gameIconInRow * 3) < gameIconInRow
                  ? blue_game_bg
                  : index % (gameIconInRow * 3) < gameIconInRow * 2
                    ? yellow_game_bg
                    : purple_game_bg
              }
              alt=""
            />
          </div> */}
          <div className="shiny-circle">
            <div className={`shiny-circle-container ${index % (gameIconInRow * 3) < gameIconInRow
              ? "blue_bg"
              : index % (gameIconInRow * 3) < gameIconInRow * 2
                ? "yellow_bg"
                : "purple_bg"}`}>
              <div className="shiny-dot"><img src={StarImg} alt="" /></div>
              <div className="shiny-dot2"><img src={StarImg2} alt="" /></div>
            </div>
          </div>
          {game.status === 2 && (
            <div className="game_under_maintenance">
              <img src={underMaintenance} alt="" />
            </div>
          )}
          {game.status === 2 ? null : (
            <div
              className="item-detail"
              {...(game.status == 1
                ? {
                    onMouseEnter: () => playMusicEvent("hoverMusic", hoverMusic, false, 1.0),
                    onMouseLeave: () => stoMusicEvent("hoverMusic", hoverMusic, false, 1.0),
                  }
                : {})}
            >
              {!blockedGames.includes(game.id) && ( // Hide game_promo_tag if game.id exists in blockedGames
                <div className="game_promo_tag">
                  {game.label_lobby.includes("hot") && (
                    <img src={hotTagBg} alt="Hot Tag" />
                  )}
                  {game.label_lobby.includes("new") && (
                    <img src={newTagBg} alt="New Tag" />
                  )}
                  {game.label_lobby.includes("promo ") && (
                    <img src={promoTagBg} alt="Promo Tag" />
                  )}
                </div>
              )}
              <div
                className="game_item_imgbox"
                onMouseEnter={() => setHoveredGameId(game.id)}
                onMouseLeave={() => setHoveredGameId(null)}
              >
                <div className="game_item_imgholder">
                  <img
                    // className="insideIconSetup"
                    // src={game.image}
                    className={`${game.status === 2
                      ? "game_under_maintenance"
                      : "insideIconSetup"
                      }`}
                    src={game.status === 2 ? underMaintenance : game.image}
                    alt="Game Icon"
                    loading="lazy"
                  />
                </div>
              </div>
              <div
                className="game_detail"
                // onClick={(e) => handleClick(game.name)}
                onClick={(e) => {
                  if (!blockedGames.includes(game.id)) {
                    handleClick("clickMusic", game.name); // Trigger only if game.id is not in blockedGames
                  }
                }}
              >
                <p>{game.name}</p>
              </div>
            </div>
          )}
          {blockedGames && blockedGames.includes(game.id) && (
            <div
              className={`game-blocked`}
              
            >
              <img src={restricted} alt="" />

              <span className="custom-tooltip-text">{game.msgRestricted}</span>
            </div>
          )}

          {game.msgRestricted !== null && (
            <div
              className={`game-restrikted ${clickedRestrictedGameId === game.id ? "show" : ""}`}
              onMouseOver={(e) => handleRestrikted(e)}
              onClick={(e) => handleRestrikted(e, game.id)}
            >
              <img src={restricted} alt="" />

              <span className="custom-tooltip-text">{game.msgRestricted}</span>
            </div>
          )}
          {/* {
            festiveTheme === 1 && <ThemeOnGame />
          } */}
        </div>
      ))}
      {showModal && !isDataLoading && (
        <div className="game_info_modal">
          <Modal closeButton={true} onCloseModal={() => setShowModal(false)}>
            <TableDataInfo
              selectedGameName={selectedGameName}
              gameCode={selectedGameCode}
              gameListImages={gameListImages}
              gameId={selectedGameId}
            />
          </Modal>
        </div>
      )}

      {showAutoTableLimitPopup && !isDataLoading && (
        <AutoTablePopup
          onClose={() => setShowAutoTableLimitPopup(false)}
          selectedGameName={selectedGameName}
          gameCode={selectedGameCode}
          gameListImages={gameListImages}
          gameId={selectedGameId}
        />
      )}
    </>
  );
};

export default withTranslation()(MainCardGameIcon);
