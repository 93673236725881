import React, { useEffect, useState, useRef } from "react";
import InformationPopup from "./InformationPopup";
import CloseIcon from "../../../assets/images/modal/close.svg";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import { useSelector } from "react-redux";
import { formatPricewithdecimal } from "../../NumberFormat";
import { withTranslation } from "react-i18next";

const AutoTable = (props) => {
  const { onClose, gameCode, selectedGameName, gameListImages, gameId } = props;
  const device = useSelector(state => state.CommonConfig.device_type);
  const userLevel = useSelector(state => state.CommonConfig.userLevel);
  const [activeIndex, setActiveIndex] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [autoTableLimit, setAutoTableLimit] = useState([]);
  const user_balance = useSelector((state) => state.UserBalance.user_balance);
  const [isExiting, setIsExiting] = useState(false);
  const modalRef = useRef();
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [gameConfirmMessage, setGameConfirmMessage] = useState("");
  const [newTableId, setNewTableId] = useState(null);
  const [currentTableId, setCurrentTableId] = useState(null);
  const [gameResponse, setGameResponse] = useState([]);
  const [showRoomPopup, setShowRoomPopup] = useState(false);
  const [selectedTableId, setSelectedTableId] = useState(null);

  const handleClick = (index, autoTableLimit) => {
    setActiveIndex(index);
    setSelectedAmount(autoTableLimit);
    if (autoTableLimit >= user_balance.coin) {
      if (device === "Mobile") {
        setShowTooltip(true);
        setTimeout(() => {
          setShowTooltip(false);
        }, 5000);
      } else {
        setShowTooltip(false);
      }
    } else {
      setShowTooltip(false);
    }
  };



  const fetchAutoTableLimit = () => {
    let reqData = {
      gameCode: gameCode,
      userLevel: userLevel,
      gameId: gameId,
    };
    makeAjax(url.CARD_GAME_API.getAutoTableLimits, reqData).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        if (response.code !== "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      if (Array.isArray(response.data)) {
        setAutoTableLimit(response.data);
      }
    });
  };

  useEffect(() => {
    fetchAutoTableLimit();
  }, [gameCode]);
  async function getRandomTableRooms(gameCode, gameId, selectedAmount) {
    let reqData = {
      gameCode: gameCode,
      gameId: gameId,
      selectedAmount: selectedAmount,
    };

    try {
      const response = await makeAjax(url.CARD_GAME_API.getRandomTableRooms, reqData);
      if (response.status !== respStatus["SUCCESS"]) {
        if (response.code !== "validation_error") {
          showMessage(response);
          return null;
        }
        showMessage(response);
        return null;
      }

      if (response.data["rooms"] && response.data["rooms"].length > 0) {
        // return response.data["rooms"][0]["table_id"];
        const tableIds = response.data["rooms"].map(room => room["table_id"]);
        return tableIds;
      } else {
        showMessage("No valid tableId found.");
        return null;
      }
    } catch (error) {
      showMessage("An unexpected error occurred. Please try again later.");
      return null;
    }
  }

  const handlePlayClick = async () => {
    if (activeIndex === null) {
      setIsPopupOpen(true);
      return;
    }
  
    try {
      let tableIds = await getRandomTableRooms(gameCode, gameId, selectedAmount);
      // console.log("Initial tableIds:", tableIds);
  
      // Declare selectedAmount as a variable that can be reassigned
      let currentSelectedAmount = selectedAmount;
  
      // If tableIds is empty, retry with lower amount
      while ((!tableIds || tableIds.length === 0) && currentSelectedAmount > 0) {
        // console.log("Retrying with lower limit...");
  
        // Declare limits using let to allow reassignment
        let limits = autoTableLimit;
        if (!limits || limits.length === 0) {
          limits = await fetchAutoTableLimit(gameCode, gameId);
          // console.log("Fetched limits:", limits);
        }
  
        if (!limits || limits.length === 0) {
          showMessage("No limits available. Please try again later.");
          return;
        }
  
        // Create a shallow copy of the limits array and sort it locally (without modifying autoTableLimit)
        let sortedLimits = [...limits].sort((a, b) => b - a);
  
        // Find the first value less than the currentSelectedAmount
        let nextValidAmount = null;
        for (const limit of sortedLimits) {
          if (limit < currentSelectedAmount) {
            nextValidAmount = limit;
            break;
          }
        }
  
        // console.log("Updated selectedAmount:", nextValidAmount);
  
        if (!nextValidAmount) {
          showMessage("No tables available for any lower amount.");
          return;
        }
  
        // Update the currentSelectedAmount and retry fetching tableIds
        currentSelectedAmount = nextValidAmount;
        tableIds = await getRandomTableRooms(gameCode, gameId, currentSelectedAmount);
        // console.log("Retried tableIds:", tableIds);
      }
  
      // Check if we got valid tableIds
      if (!tableIds || tableIds.length === 0) {
        showMessage("No valid table IDs found.");
        return;
      }
  
      // If we only have one tableId, go directly to the game
      if (tableIds.length === 1) {
        const tableId = tableIds[0]; // Single tableId
        let reqData = {
          gameCode: gameCode,
          tableId: tableId,
        };
  
        try {
          // Make the AJAX request to get the game link
          const response = await makeAjax(url.CARD_GAME_API.getGameLink, reqData);
  
          if (response.status === respStatus["SUCCESS"]) {
            // Call gotoGame if successful
            setNewTableId(tableId);
            setGameResponse(response);
            gotoGame(response, gameCode, tableId); // Directly call gotoGame
            return; // Exit once the game has been launched
          } else if (response.status === "ERROR") {
            showMessage(props.t(response.code));
            // Retry with a lower amount if there is an error with the single table ID
            // console.log("Single table ID failed, retrying with lower amount...");
            let nextValidAmount = await getNextValidLimit(autoTableLimit, currentSelectedAmount);
            if (!nextValidAmount) {
              showMessage("No tables available for any lower amount.");
              return;
            }
            currentSelectedAmount = nextValidAmount;
            tableIds = await getRandomTableRooms(gameCode, gameId, currentSelectedAmount);
            // console.log("Retried tableIds after failure:", tableIds);
            
            // If retry tableIds exist, perform gotoGame with the valid tableId
            if (tableIds && tableIds.length > 0) {
              const newTableId = tableIds[0];
              let retryReqData = {
                gameCode: gameCode,
                tableId: newTableId,
              };
  
              try {
                const retryResponse = await makeAjax(url.CARD_GAME_API.getGameLink, retryReqData);
  
                if (retryResponse.status === respStatus["SUCCESS"]) {
                  // Proceed with the game
                  setNewTableId(newTableId);
                  setGameResponse(retryResponse);
                  gotoGame(retryResponse, gameCode, newTableId);
                  return; // Exit once the game is launched
                } else {
                  // showMessage(props.t(retryResponse.code));
                }
              } catch (error) {
                console.error("Error fetching game link for retried tableId:", newTableId, error);
                showMessage("An unexpected error occurred. Please try again later.");
              }
            }
          } else {
            showMessage(response);
          }
        } catch (error) {
          console.error("Error fetching game link for tableId:", tableId, error);
          showMessage("An unexpected error occurred. Please try again later.");
        }
        return; // Exit if the tableId is successfully processed or retried
      }
  
      // If tableIds is an array (multiple values), iterate and try each tableId
      let success = false;
      for (const tableId of tableIds) {
        let reqData = {
          gameCode: gameCode,
          tableId: tableId,
        };
  
        try {
          // Make the AJAX request to get the game link
          const response = await makeAjax(url.CARD_GAME_API.getGameLink, reqData);
  
          if (response.status === respStatus["SUCCESS"]) {
            // Call gotoGame if successful
            setNewTableId(tableId);
            setGameResponse(response);
            gotoGame(response, gameCode, tableId);
            success = true;
            return; // Exit once a valid tableId is found
          } else if (response.status === "ERROR") {
            // showMessage(props.t(response.code)+' tableId : '+tableId);
          } else {
            showMessage(response);
          }
        } catch (error) {
          console.error("Error fetching game link for tableId:", tableId, error);
          showMessage("An unexpected error occurred. Please try again later.");
        }
      }
  
      if (!success) {
        showMessage("No valid table IDs found.");
      }
    } catch (error) {
      console.error("Error in handlePlayClick:", error);
      showMessage("Failed to fetch table IDs or limits. Please try again later.");
    }
  };
  
  // Helper function to get the next valid limit
  const getNextValidLimit = async (autoTableLimit, currentSelectedAmount) => {
    let limits = autoTableLimit;
    if (!limits || limits.length === 0) {
      limits = await fetchAutoTableLimit(gameCode, gameId);
    }
  
    if (!limits || limits.length === 0) {
      return null;
    }
  
    // Create a shallow copy of the limits array and sort it locally
    let sortedLimits = [...limits].sort((a, b) => b - a);
  
    // Find the first value less than the currentSelectedAmount
    let nextValidAmount = null;
    for (const limit of sortedLimits) {
      if (limit < currentSelectedAmount) {
        nextValidAmount = limit;
        break;
      }
    }
    return nextValidAmount;
  };

  let openedTabs = {};

  function gotoGame(response, gameCode, tableId) {
    const token = response.data.token;
    const operatorid = response.data.operatorid;
    const username = response.data.username;
    const one_time_token = response.data.one_time_token;
    const linkparent = response.data.linkparent;
    const encrypted_value = response.data.encrypted_value;

    let url = response.data.link;
    // let url = 'http://127.0.0.1:8004';
    const gameUrl = "/api/cardGame";
    let query_param = {
      gametypex: gameCode.toUpperCase(),
      tableid: tableId,
      token: token,
      operatorid: operatorid,
      username: username,
      one_time_token: one_time_token,
      linkparent: linkparent,
      randomlimit:selectedAmount,
      encrypted_value: encrypted_value
    };

    let param_string = Object.keys(query_param)
      .map((key) => {
        return (
          encodeURIComponent(key) + "=" + encodeURIComponent(query_param[key])
        );
      })
      .join("&");
    url = url + gameUrl + "?" + param_string;

    let tabName = openedTabs[gameCode] || `gameTab-${gameCode}`;

    const target = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
      ? "_self"
      : tabName;

    const newTab = window.open(url, target);
  }

  const handleinfoClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleModalClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      setIsExiting(false);
      onClose();
    }, 300);
  };
  useEffect(() => {
    if (Array.isArray(autoTableLimit) && autoTableLimit.length > 0) {
      const minLimit = Math.min(...autoTableLimit);
      const highestEnabledIndex = autoTableLimit
        .map((amount, index) => ({ amount, index }))
        .filter(({ amount }) => user_balance.coin < minLimit || amount <= user_balance.coin) // Condition for enabling
        .reduce((prev, current) => (prev.amount > current.amount ? prev : current), { amount: 0, index: null }).index;

      if (highestEnabledIndex !== null) {
        setActiveIndex(highestEnabledIndex);
        setSelectedAmount(autoTableLimit[highestEnabledIndex]);
      }
    }
  }, [autoTableLimit, user_balance.coin]);


  return userLevel === 0 ? (
    <>
      <div className="modal_overlay" onClick={handleModalClose}></div>
      <div className="pokerdealer-popup">
        <div className={`modal ${!isExiting ? "popupani" : ""}`} ref={modalRef}>
          <div className="modal_close">
            <img src={CloseIcon} alt="close" onClick={handleModalClose} />
          </div>
          <div className="dealer-popup">
            <div className="poker-deler-img">
              <p>
                {gameListImages[gameId] && (
                  <img src={gameListImages[gameId]} alt={gameId} />
                )}
                {selectedGameName}
              </p>
            </div>
            <div className="popup-heading">
              <h5>Auto Table</h5>
            </div>
            <div className="auto-table">
              <div className="game-amount">
                {Array.isArray(autoTableLimit) &&
                  autoTableLimit.map((amount, index) => {
                    const isDisabled =
                      user_balance.coin >= autoTableLimit[0] &&
                      amount > user_balance.coin;
                    return (
                      <div
                        key={index}
                        className={`amount ${index === activeIndex ? "active" : ""
                          } ${isDisabled ? "disabled" : ""}`}
                        onClick={() => {
                          if (!isDisabled) handleClick(index, amount);
                        }}
                        onMouseEnter={() => isDisabled && setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                      >
                        {formatPricewithdecimal(amount)}
                        {showTooltip && isDisabled && (
                          <div className="tooltip">
                            {props.t("balance_play")}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className="poker-ply-btn">
                <button className="play-btn" onClick={handlePlayClick}>
                  PLAY
                </button>
              </div>
            </div>
            {isPopupOpen && <InformationPopup onClose={handleinfoClosePopup} />}
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="game-amount">
        {Array.isArray(autoTableLimit) &&
          autoTableLimit.map((amount, index) => {
            const isDisabled =
              user_balance.coin >= autoTableLimit[0] &&
              amount > user_balance.coin;
            return (
              <div
                key={index}
                className={`amount ${index === activeIndex ? "active" : ""
                  } ${isDisabled ? "disabled" : ""}`}
                onClick={() => {
                  if (!isDisabled) handleClick(index, amount);
                }}
                onMouseEnter={() => isDisabled && setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                {formatPricewithdecimal(amount)}
                {showTooltip && isDisabled && (
                  <div className="tooltip">
                    {props.t("balance_play")}
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <div className="poker-ply-btn">
        <button className="play-btn" onClick={handlePlayClick}>
          PLAY
        </button>
      </div>
      {isPopupOpen && <InformationPopup onClose={handleinfoClosePopup} />}
    </>
  );

};

export default withTranslation()(AutoTable);
