import React, { useEffect, useRef, useState } from "react";
import { makeAjax, respStatus, showMessage, url } from "../../helpers/global_helper";
import { useLocation } from "react-router-dom";

const Promotion = () => {
    const location = useLocation();
    const { id, activeState } = location.state || {};
    const [banners, setBanners] = useState([]);
    const [currentActive, setCurrentActive] = useState(activeState || id || null);
    const activeRef = useRef(null);
    const [htmlData, setHtmlData] = useState('');

    useEffect(() => {
        const currentDate = new Date().toISOString().split("T")[0];
        const storedBanners = localStorage.getItem("promoBanners");

        const fetchAndSetBanners = () => {
            makeAjax(url.PLAYER_API.getPromoBanner, { date: currentDate }, url.PLAYER_MS_EXT).then((response) => {
                if (response.status !== respStatus["SUCCESS"]) {
                    showMessage(response);
                    return;
                }
                localStorage.setItem("promoBanners", JSON.stringify(response.data));
                setBanners(response.data);
            });
        };

        if (storedBanners) {
            setBanners(JSON.parse(storedBanners));
            fetchAndSetBanners();
        } else {
            fetchAndSetBanners();
        }
    }, []);

    useEffect(() => {
        if (banners.length > 0 && currentActive) {
            setTimeout(() => {
                if (activeRef.current) {
                    activeRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            }, 100);
        }
    }, [banners, currentActive,htmlData]);


    const handleMoreInfoClick = (id) => {
        setCurrentActive((prevActive) => (prevActive === id ? null : id));
    };

    useEffect(() => {
        // Inject CSS for list styles
        const style = document.createElement('style');
        style.innerHTML = `
          ul {
            list-style-type: disc; /* This ensures the bullet points are shown */
            padding-left: 20px; /* Add some indentation */
            color: #FFFFFF !important;
          }
      
          li {
            color: #FFFFFF !important; /* Set the text color to white */
          }
         p{
            color: #FFFFFF !important;
         }
         span{
            color: #FFFFFF !important;
         }
        ul li::marker {
                color: #FFFFFF !important;
        }
        `;
        document.head.appendChild(style);
      
        const currentDate = new Date().toISOString().split("T")[0];
      
        makeAjax(
          url.PLAYER_API.getPromoBanner,
          { date: currentDate },
          url.PLAYER_MS_EXT
        ).then((response) => {
          if (response.status !== respStatus["SUCCESS"]) {
            showMessage(response);
            return;
          }
          setBanners(response.data);
          console.log("response.data", response.data);
      
          // Store HTML data for each banner individually
          const newHtmlData = {};
          response.data.forEach((banner) => {
            newHtmlData[banner.id] = banner.body || ""; // Assuming 'body' holds the HTML content
          });
          setHtmlData(newHtmlData);
          console.log("harsh",htmlData);
          
        });
      
        return () => {
          document.head.removeChild(style);
        };
      }, []);

    return (
        <div className="promotions-container">
            {banners.map((banner) => (
                banner.redirect_link === 'promotion' && (
                    <div
                        key={banner.id}
                        className={`promotion ${currentActive === banner.id ? "active" : ""}`}
                        id={`promo-${banner.id}`}
                        ref={currentActive === banner.id ? activeRef : null}
                    >
                        <div className="promotion-banner-holder">

                            <img
                                src={banner.updated_web_banner}
                                alt="Desktop Banner"
                                className="desktop_banner_img"
                                name={banner.redirect_link}
                                onClick={() => handleMoreInfoClick(banner.id)}
                            />
                            <img
                                src={banner.updated_mobile_banner}
                                alt="Mobile Banner"
                                className="mobile_portrait_banner_img"
                                name={banner.redirect_link}
                                onClick={() => handleMoreInfoClick(banner.id)}
                            />

                        </div>
                        <div
                            className={`promo-content-outer ${currentActive === banner.id ? "highlight" : ""
                                }`}
                            style={{
                                maxHeight: currentActive === banner.id ? 1000 : 0,
                                transition: "max-height 0.3s ease, background-color 0.3s ease",
                            }}
                        >
                            <div className="promo-content">
                                <div className="promotion_content_header">
                                    <h4>Start date : {new Date(banner.date_start).toLocaleDateString()}</h4>
                                    <h4>END date : {new Date(banner.date_end).toLocaleDateString()}</h4>
                                </div>
                                <div className="promotion-cnt">
                                    <h2 className="promotion-heading">{banner.title}</h2>
                                    {/* {banner.body.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, "").trim()} */}
                                    <div dangerouslySetInnerHTML={{ __html: htmlData[banner.id] || '' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            ))}
        </div>
    );
};

export default Promotion;
