import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { makeAjax, respStatus, showMessage, url } from "../../../helpers/global_helper";
import { formatPricewithdecimal } from '../../NumberFormat'
import DownArrow from "../../../assets/images/icons/down_arrow.svg";
import UpArrow from "../../../assets/images/icons/up_arrow.svg"
import { withTranslation } from "react-i18next";
import TurnoverDatePopup from '../InformationPopups/TurnoverDatePopup';
import { setTotalNoResult } from '../../../redux/actions';

const TurnoverInfo = (props) => {
  const dispatch = useDispatch()
  const [turnoverInfoData, setTurnoverInfoData] = useState([]);
  const [turnoverPopup, setTurnoverPopup] = useState(false);
  const [turnoverDateId, setTurnoverDateId] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(false)
  const pageNumber = useSelector((state) => state.paginationData.pageNumber);
  const perPage = useSelector((state) => state.paginationData.dataInOnePage);


  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const sortedData = () => {
    if (sortBy) {
      const sortedArray = turnoverInfoData.slice().sort((a, b) => {
        let aValue, bValue;

        if (sortBy === "created_date") {
          aValue = new Date(a[sortBy]);
          bValue = new Date(b[sortBy]);
        } else if (sortBy === "winlose" || sortBy === "tover") {
          aValue = parseFloat(a[sortBy].replace(/[^0-9.-]/g, ""));
          bValue = parseFloat(b[sortBy].replace(/[^0-9.-]/g, ""));
        } else {
          aValue =
            typeof a[sortBy] === "string" ? a[sortBy].toLowerCase() : a[sortBy];
          bValue =
            typeof b[sortBy] === "string" ? b[sortBy].toLowerCase() : b[sortBy];
        }

        if (sortBy === "winlose") {
          return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
        }

        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      });

      return sortedArray;
    }
    return turnoverInfoData;
  };



  const openTurnoverDatePopup = (turnoverDateId) => {
    setTurnoverDateId(turnoverDateId);
    setTurnoverPopup(true);
  };

  const closeTurnoverDatePopup = () => {
    setTurnoverPopup(false);
  };


  // api for Turnover Info list
  const fetchTurnoverInfo = () => {
    setLoading(true)
    let reqData = {
      page: pageNumber,
      length: perPage

    };
    console.log("==reqData",reqData)
    makeAjax(url.PLAYER_API.listTurnover, reqData, url.PLAYER_MS_EXT).then((response) => {

      if (response.status !== respStatus["SUCCESS"]) {
        if (response.code !== "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      setTurnoverInfoData(response.data.data);
      dispatch(setTotalNoResult(response.data.total))
      setLoading(false)
    });
  };

  useEffect(() => {
    fetchTurnoverInfo();
    const container = document.querySelector(".table_container");
    if (container) {
      container.scrollTop = 0;
    }
  }, [perPage, pageNumber]);

  return (
    <div className="tab_content">
      <div className="table_main">
        <table className='dataTableMenuBar' border="0">
          <thead>
            <tr>
              <th onClick={() => handleSort("created_date")}>
                {props.t("date")}
                <span className="sorting_arrow">
                  <img
                    src={
                      sortBy === "created_date" && sortOrder === "asc"
                        ? UpArrow
                        : DownArrow
                    }
                    alt=""
                  />
                </span>
              </th>
              <th onClick={() => handleSort("winlose")} style={{ textAlign: "center" }}>
                {props.t("win")} / {props.t("lost")}
                <span className="sorting_arrow">
                  <img
                    src={
                      sortBy === "winlose" && sortOrder === "asc"
                        ? UpArrow
                        : DownArrow
                    }
                    alt=""
                  />
                </span>
              </th>
              <th onClick={() => handleSort("tover")}>
                {props.t("turnover")}
                <span className="sorting_arrow">
                  <img
                    src={
                      sortBy === "tover" && sortOrder === "asc"
                        ? UpArrow
                        : DownArrow
                    }
                    alt=""
                  />
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="3">Loading...</td>
              </tr>
            ) : 
              (sortedData().length === 0 ?
                <tr className="table_data_empty_row">
                  <td colSpan="3">{props.t("data_not_found")}</td>
                </tr> :
                (sortedData().map((item, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "first_bg" : "second_bg"}
                  >
                    <td onClick={() => openTurnoverDatePopup(item.created_date)}>
                      <span className="link">{item.created_date}</span>
                    </td>
                    <td>{formatPricewithdecimal(item.winlose)}</td>
                    <td>{formatPricewithdecimal(item.tover)}</td>
                  </tr>
                ))))
            }
          </tbody>

        </table>
      </div>
      {turnoverPopup && (
        <TurnoverDatePopup
          onClose={closeTurnoverDatePopup}
          turnoverDateId={turnoverDateId}
        />
      )}
    </div>


  )
}

export default withTranslation()(TurnoverInfo)
