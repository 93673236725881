import React, { useEffect, useState } from 'react'
import { makeAjax, respStatus, showMessage, url } from "../../../helpers/global_helper";
import { formatPricewithdecimal } from '../../NumberFormat'
import DownArrow from "../../../assets/images/icons/down_arrow.svg";
import UpArrow from "../../../assets/images/icons/up_arrow.svg"
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { setTotalNoResult } from '../../../redux/actions';

const EventBonus = (props) => {
  const dispatch = useDispatch()
  const [eventBonusData, setEventBonusData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(false);
  const pageNumber = useSelector((state) => state.paginationData.pageNumber)
  const perPage = useSelector((state) => state.paginationData.dataInOnePage)

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };


  const sortedData = () => {
    let dataArray = [];

    if (Array.isArray(eventBonusData)) {
      dataArray = eventBonusData; // Already an array, no conversion needed
    } else if (eventBonusData && typeof eventBonusData === "object") {
      dataArray = Object.values(eventBonusData); // Convert object values to an array
    } else {
      return []; // Return an empty array if eventBonusData is null, undefined, or invalid
    }

    console.log("dataArray", dataArray)
    if (sortBy === "date") {
      return dataArray.slice().sort((a, b) => {
        const aValue = new Date(a.date).getTime();
        const bValue = new Date(b.date).getTime();
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      });
    } else if (sortBy === "tover" || sortBy === "amount") {
      return dataArray.slice().sort((a, b) => {
        const aValue = parseFloat(a[sortBy]);
        const bValue = parseFloat(b[sortBy]);
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      });
    } else if (sortBy === "name" || sortBy === "game_name") {
      return dataArray.slice().sort((a, b) => {
        const aValue = a.name.toLowerCase();
        const bValue = b.name.toLowerCase();
        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });
    }
    return dataArray; // Default: no sorting applied
  };


  // api for Event Bonus list
  const fetchEventBonusList = () => {
    setLoading(true)

    let reqData = {
      // type: "bonus_event",
      page: pageNumber,
      length: perPage,
    };

    makeAjax(url.PLAYER_API.listEventBonus, reqData, url.PLAYER_MS_EXT).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        if (response.code !== "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      const transformedData = Object.values(response.data.data);
      setEventBonusData(transformedData);
      dispatch(setTotalNoResult(response.data.total));
      setLoading(false);
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchEventBonusList(pageNumber, perPage);
    }, 200);
    return () => clearTimeout(timer);
  }, [pageNumber, perPage, sortBy, sortOrder]);


  // Helper function to map game_type to names
  const getGameTypeName = (gameType) => {
    switch (gameType) {
      case 3:
        return "CardGame";
      case 2:
        return "Dingdong";
      case 5:
        return "Togel";
      default:
        return "Unknown";
    }
  };

  return (
    <div className="tab_content" >
      <div className="table_main">
        <table className='dataTableMenuBar' border="0">
          <thead>
            <tr>
              <th onClick={() => handleSort("date")}>
                {props.t("date")}
                <span className="sorting_arrow">
                  <img src={sortBy === "date" && sortOrder === "asc" ? UpArrow : DownArrow} alt="" />
                </span>
              </th>
              <th onClick={() => handleSort("name")}>
                {props.t("event")}
                <span className="sorting_arrow">
                  <img src={sortBy === "name" && sortOrder === "asc" ? UpArrow : DownArrow} alt="" />
                </span>

              </th>
              <th onClick={() => handleSort("game_name")}>
                {props.t("game")}
                <span className="sorting_arrow">
                  <img src={sortBy === "game_name" && sortOrder === "asc" ? UpArrow : DownArrow} alt="" />
                </span>
              </th>
              <th onClick={() => handleSort("tover")}>
                {props.t("turnover")}
                <span className="sorting_arrow">
                  <img src={sortBy === "tover" && sortOrder === "asc" ? UpArrow : DownArrow} alt="" />
                </span>
              </th>
              <th>{props.t("category")}</th>
              <th onClick={() => handleSort("amount")}>
                {props.t("bonus_amount")}
                <span className="sorting_arrow">
                  <img src={sortBy === "amount" && sortOrder === "asc" ? UpArrow : DownArrow} alt="" />
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="6">Loading...</td>
              </tr>
            ) : (
              sortedData().map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "first_bg" : "second_bg"}
                >
                  <td>{item.date}</td>
                  <td>{item.name}</td>
                  <td>{item.game_name}</td>
                  <td>{formatPricewithdecimal(item.tover)}</td>
                  <td>{getGameTypeName(item.game_type)}</td>
                  <td>{formatPricewithdecimal(item.amount)}</td>
                </tr>
              ))
            )}
            {sortedData().length === 0 && !loading && (
              <tr className="table_data_empty_row">
                <td colSpan="6">{props.t("data_not_found")}</td>
              </tr>
            )}
          </tbody>

        </table>
      </div>
    </div>
  );
};

export default withTranslation()(EventBonus);
