import React, { useEffect, useState } from 'react'
import { makeAjax, respStatus, showMessage, url } from "../../../helpers/global_helper";
import { formatPricewithdecimal } from '../../NumberFormat'
import DownArrow from "../../../assets/images/icons/down_arrow.svg";
import UpArrow from "../../../assets/images/icons/up_arrow.svg"
import { withTranslation } from "react-i18next";
import { setTotalNoResult } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';


const MemberList = (props) => {

  const dispatch = useDispatch()

  const [memberListData, setMemberListData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(false)
  const pageNumber = useSelector((state) => state.paginationData.pageNumber)
  const perPage = useSelector((state) => state.paginationData.dataInOnePage)
  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };
  const sortedData = () => {
    if (sortBy === "date" || sortBy === "joindate") {
      return memberListData.slice().sort((a, b) => {
        const aValue = new Date(a[sortBy]).getTime();
        const bValue = new Date(b[sortBy]).getTime();
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      });
    } else if (sortBy === "turnover" || sortBy === "commission") {
      return memberListData.slice().sort((a, b) => {
        const aValue = parseFloat(a[sortBy]);
        const bValue = parseFloat(b[sortBy]);
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      });
    } else if (sortBy) {
      return memberListData.slice().sort((a, b) => {
        const aValue = a[sortBy].toLowerCase();
        const bValue = b[sortBy].toLowerCase();
        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });
    }
    return memberListData;
  };

  // api for Transaction list
  const fetchEventBonusList = () => {
    setLoading(true);
    let reqData = {
      page: pageNumber,
      length: perPage,
    };
    makeAjax(url.PLAYER_API.listReferral, reqData, url.PLAYER_MS_EXT).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        if (response.code !== "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      setMemberListData(response.data.data);
      dispatch(setTotalNoResult(response.data.data.length))
      setLoading(false);
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchEventBonusList(pageNumber, perPage);
    }, 200);
    return () => clearTimeout(timer);
  }, [pageNumber, perPage, sortBy, sortOrder]);

  return (
    <div className="tab_content" >
      <div className="table_main">
        <table className='dataTableMenuBar' border="0">
          <thead>
            <tr>
              <th onClick={() => handleSort("commission")}>
                {props.t("no.")}
                {/* No */}

              </th>
              <th onClick={() => handleSort("commission")}>
                {props.t("username")}
                <span className="sorting_arrow">
                  <img
                    src={
                      sortBy === "commission" && sortOrder === "asc"
                        ? UpArrow
                        : DownArrow
                    }
                    alt=""
                  />
                </span>
              </th>
              <th onClick={() => handleSort("joindate")}>
                {props.t("register_date")}
                {/* Register Date */}
                <span className="sorting_arrow">
                  <img
                    src={
                      sortBy === "joindate" && sortOrder === "asc"
                        ? UpArrow
                        : DownArrow
                    }
                    alt=""
                  />
                </span>
              </th>
              <th onClick={() => handleSort("turnover")}>
                {props.t("turnover")}
                {/* Turnover */}
                <span className="sorting_arrow">
                  <img
                    src={
                      sortBy === "turnover" && sortOrder === "asc"
                        ? UpArrow
                        : DownArrow
                    }
                    alt=""
                  />
                </span>
              </th>
              <th onClick={() => handleSort("commission")}>
                {props.t("commision")}
                {/* Commision */}
                <span className="sorting_arrow">
                  <img
                    src={
                      sortBy === "commission" && sortOrder === "asc"
                        ? UpArrow
                        : DownArrow
                    }
                    alt=""
                  />
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5">Loading...</td>
              </tr>
            ) : (
              sortedData().length === 0 ? (
                <tr className="table_data_empty_row">
                  <td colSpan="5">{props.t("data_not_found")}</td>
                </tr>
              ) : (
                sortedData().map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "first_bg" : "second_bg"}
                    >
                      <td>{index + 1}</td>
                      <td>{item.nickname}</td>
                      <td>
                        <p>{item.joindate}</p>
                      </td>
                      <td>{formatPricewithdecimal(item.turnover)}</td>
                      <td>{formatPricewithdecimal(parseFloat(item.commission).toFixed(2))}</td>
                    </tr>
                  );
                })
              )
            )}
          </tbody>

        </table>
      </div>
    </div>
  )
}

export default withTranslation()(MemberList)
